import React, { Component } from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"
import axios from "axios"
import qs from "qs"
import ReactHtmlParser from "react-html-parser"

import Layout from '../../components/layout'
import SEO from '../../components/seo'

class JobDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ghScriptLoaded: false,
      ghData: [],
      paraText: null,
    }
  }

  componentDidMount() {
    // get data
    let qsParam = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).gh_jid
    let url = `https://boards-api.greenhouse.io/v1/boards/wk/jobs/` + qsParam
    // let job_id = this.props.id
    axios
      .get(url)
      .then(response => {
        const departments = response.data
        this.setState({ ghData: departments, jobId: response.data.id})
        setTimeout(() => {
          typeof window !== "undefined" && window.Grnhse.Iframe.load(response.data.id)
        }, 1500)
      })
      .catch(error => {
        console.log(error)
      })
  }
  

  render() {
    // console.log(this.state.ghData)
    let apiDescription = ReactHtmlParser(this.state.ghData.content)
    return (
      <Layout>
        <SEO title={`Job Description`} description={`Job Description`} />
      <div>
        <section className="qa_index container-fluid">
          <header className="single__header">
            <p className="h4 text-center">
              <Link to="/jobs/">Jobs</Link>
            </p>
          </header>

          {/* top header intro */}
          <div className="row">
            <header className="w-100">
              {/* intro text */}
              <hgroup className="row mb-4">
                <h1 className="col-12 center h1_jobs_mobile">
                  {this.state.ghData.title}
                </h1>
                {/* <h4
                  className="col-12 center"
                  dangerouslySetInnerHTML={{
                    __html: this.state.ghData.content,
                  }}
                /> */}
                {/* <code>{this.state.ghData.content}</code> */}
                <div
                  className="col-12 col-md-10 col-lg-8 mx-auto entry"
                  dangerouslySetInnerHTML={{ __html: apiDescription }}
                />
              </hgroup>
            </header>
          </div>

          <div className="row">
            <div className="col-12 col-md-10 col-lg-8 mx-auto entry border-top pt-4">
              <div id="grnhse_app" />
              <Helmet>
                <script
                  src="https://boards.greenhouse.io/embed/job_board/js?for=wk"
                  type="text/javascript"
                />
              </Helmet>
            </div>
          </div>
        </section>
      </div>
      </Layout>
    )
  }
}

export default JobDetails
